/*
Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.

Licensed under the Apache License, Version 2.0 (the "License").
You may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/
import React, { useMemo } from 'react';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Checkbox,
  CircularProgress,
  Radio,
} from '@mui/material';

interface Column<T> {
  id: string;
  label: string;
  render: (item: T) => React.ReactNode;
  width?: number | string;
  className?: string;
}

interface PaginatedTableProps<T> {
  dummyPagination?: boolean;
  totalCount?: number;
  singleSelection?: boolean;
  columns: Column<T>[];
  data: T[];
  page: number;
  rowsPerPage: number;
  loading: boolean;
  onPageChange: (event: any, newPage: number) => void;
  onRowsPerPageChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  selectedItems?: string[];
  getItemId?: (item: T) => string;
  onSelectAllClick?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onItemClick?: (id: string) => void;
}

export function PaginatedTable<T>({
  columns,
  data,
  selectedItems,
  page,
  rowsPerPage,
  loading,
  getItemId,
  onSelectAllClick,
  onItemClick,
  onPageChange,
  onRowsPerPageChange,
  dummyPagination,
  totalCount,
  singleSelection,
}: Readonly<PaginatedTableProps<T>>) {
  const paginatedData = useMemo(() => {
    return dummyPagination
      ? data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      : data;
  }, [data, page, rowsPerPage, dummyPagination]);

  const hasSelection = !!selectedItems && !!getItemId;

  const allSelected = useMemo(() => {
    return (
      hasSelection &&
      paginatedData.length > 0 &&
      selectedItems.length === paginatedData.length
    );
  }, [hasSelection, paginatedData, selectedItems]);

  const indeterminate = useMemo(() => {
    return (
      hasSelection &&
      selectedItems.length > 0 &&
      selectedItems.length < paginatedData.length
    );
  }, [hasSelection, selectedItems, paginatedData]);

  const isSelected = useMemo(() => {
    return (id: string) => hasSelection && selectedItems.indexOf(id) !== -1;
  }, [hasSelection, selectedItems]);

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height={400}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {hasSelection && (
                <TableCell padding="checkbox">
                  {!singleSelection && (
                    <Checkbox
                      indeterminate={indeterminate}
                      checked={allSelected}
                      onChange={onSelectAllClick}
                    />
                  )}
                </TableCell>
              )}
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  style={{ width: column.width }}
                  className={column.className}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedData.map((item, index) => {
              const id = getItemId ? getItemId(item) : index.toString();
              const isItemSelected = hasSelection && isSelected(id);
              return (
                <TableRow
                  hover
                  onClick={
                    hasSelection && onItemClick
                      ? () => onItemClick(id)
                      : undefined
                  }
                  role={hasSelection ? 'checkbox' : undefined}
                  aria-checked={isItemSelected}
                  tabIndex={-1}
                  key={id}
                  selected={isItemSelected}
                >
                  {hasSelection && (
                    <TableCell padding="checkbox">
                      {singleSelection ? (
                        <Radio checked={isItemSelected} />
                      ) : (
                        <Checkbox checked={isItemSelected} />
                      )}
                    </TableCell>
                  )}
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      sx={{ maxWidth: column.width }}
                      className={column.className}
                    >
                      {column.render(item)}
                    </TableCell>
                  ))}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 20, 30]}
        component="div"
        count={totalCount ?? data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
      />
    </>
  );
}
