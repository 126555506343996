/*
Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.

Licensed under the Apache License, Version 2.0 (the "License").
You may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Link,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import {
  CreateExternalGlossaryCollectionResponseDtoStatusEnum,
  GlossaryCollectionDto,
  GlossaryCollectionDtoStatusEnum,
  GlossaryCollectionDtoTypeEnum,
} from '@ink-ai/insight-service-sdk';
import { formatDate } from '@ink-ai/portal/common/utils';
import { PaginatedTable } from '../../../common/components/paginated-table/PaginatedTable';
import { EllipsisCell } from '../../../common/components/paginated-table/EllipsisCell';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@ink-ai/portal/reducers';
import {
  listGlossaryCollections,
  statusClassMapping,
} from '@ink-ai/portal/reducers/glossary';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import PendingOutlinedIcon from '@mui/icons-material/PendingOutlined';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';

export const statusIconMapping: Record<
  CreateExternalGlossaryCollectionResponseDtoStatusEnum,
  React.ReactNode
> = {
  [CreateExternalGlossaryCollectionResponseDtoStatusEnum.PendingUpload]: (
    <PendingOutlinedIcon />
  ),
  [CreateExternalGlossaryCollectionResponseDtoStatusEnum.Importing]: (
    <PendingOutlinedIcon />
  ),
  [CreateExternalGlossaryCollectionResponseDtoStatusEnum.Ready]: (
    <CheckCircleOutlineOutlinedIcon />
  ),
  [CreateExternalGlossaryCollectionResponseDtoStatusEnum.Error]: (
    <ErrorOutlineOutlinedIcon />
  ),
};

interface GlossaryCollectionTableProps {
  selectedGlossaries: string[];
  searchTerm: string;
  onNavigate?: (glossary: GlossaryCollectionDto) => void;
  page: number;
  onPageChange: (page: number) => void;
  onSelectedGlossariesChange: (selectedGlossaries: string[]) => void;
  onTotalItemCountChange?: (total: number) => void;
  allowNonReadySelection?: boolean;
}

export const GlossaryCollectionTable: React.FC<
  GlossaryCollectionTableProps
> = ({
  selectedGlossaries,
  onSelectedGlossariesChange,
  searchTerm,
  onNavigate,
  page,
  onPageChange,
  onTotalItemCountChange,
  allowNonReadySelection = true,
}) => {
  const dispatch = useDispatch<any>();
  const glossary = useSelector((state: RootState) => state.glossary);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const formatter = new Intl.NumberFormat('en-US');
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    dispatch(listGlossaryCollections());
  }, [dispatch]);

  useEffect(() => {
    if (onTotalItemCountChange) {
      const internalItemCount = selectedGlossaries.reduce((sum, uuid) => {
        const glossaryItem = glossary.glossaries.find((g) => g.uuid === uuid);
        if (
          glossaryItem &&
          glossaryItem.type !== GlossaryCollectionDtoTypeEnum.External
        ) {
          return sum + (glossaryItem.itemCount || 0);
        }
        return sum;
      }, 0);
      onTotalItemCountChange(internalItemCount);
    }
  }, [selectedGlossaries, glossary.glossaries, onTotalItemCountChange]);

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const currentPageGlossaries = filteredGlossaries.slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage,
      );
      const newSelecteds = currentPageGlossaries.map((g) => g.uuid);
      onSelectedGlossariesChange(newSelecteds);
    } else {
      onSelectedGlossariesChange([]);
    }
  };

  const handleClick = (uuid: string) => {
    const glossaryItem = glossary.glossaries.find((g) => g.uuid === uuid);
    if (
      !allowNonReadySelection &&
      glossaryItem?.status !== GlossaryCollectionDtoStatusEnum.Ready
    ) {
      return;
    }
    const selectedIndex = selectedGlossaries.indexOf(uuid);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedGlossaries, uuid);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedGlossaries.slice(1));
    } else if (selectedIndex === selectedGlossaries.length - 1) {
      newSelected = newSelected.concat(selectedGlossaries.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedGlossaries.slice(0, selectedIndex),
        selectedGlossaries.slice(selectedIndex + 1),
      );
    }

    onSelectedGlossariesChange(newSelected);
  };

  const handlePageChange = (
    _: React.ChangeEvent<HTMLInputElement>,
    newPage: number,
  ) => {
    onPageChange(newPage);
  };

  const handleRowsPerPageChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    onPageChange(0);
  };

  const filteredGlossaries = useMemo(
    () =>
      (glossary.glossaries || []).filter((g) =>
        g.name.toLowerCase().includes(searchTerm.toLowerCase()),
      ),
    [glossary.glossaries, searchTerm],
  );

  const { t } = useTranslation();

  const columns = useMemo(
    () => [
      {
        id: 'name',
        label: t('table.name'),
        width: 200,
        render: (glossary: GlossaryCollectionDto) => (
          <EllipsisCell
            content={
              <Link
                component="span"
                underline="hover"
                onClick={() => onNavigate && onNavigate(glossary)}
                style={{ cursor: 'pointer' }}
              >
                {glossary.name}
              </Link>
            }
            tooltip={glossary.name}
          />
        ),
      },
      {
        id: 'description',
        label: t('table.description'),
        width: 200,
        render: (glossary: GlossaryCollectionDto) => (
          <EllipsisCell content={glossary.desc || '-'} />
        ),
        hidden: isSmallScreen,
      },
      {
        id: 'terms',
        label: t('table.terms'),
        render: (glossary: GlossaryCollectionDto) =>
          glossary.status === GlossaryCollectionDtoStatusEnum.Ready
            ? formatter.format(glossary.itemCount)
            : t('common.na'),
      },
      {
        id: 'storage',
        label: t('table.storage'),
        render: (glossary: GlossaryCollectionDto) =>
          glossary.type === GlossaryCollectionDtoTypeEnum.Internal
            ? t('glossary.storage.inMemory')
            : t('glossary.storage.openSearch'),
        hidden: isMediumScreen,
      },
      {
        id: 'updatedAt',
        label: t('table.updatedAt'),
        render: (glossary: GlossaryCollectionDto) =>
          formatDate(glossary.updatedAt),
        width: 150,
        hidden: isMediumScreen,
      },
      {
        id: 'status',
        label: t('table.status'),
        render: (glossary: GlossaryCollectionDto) => {
          const content = (
            <Typography
              variant="body2"
              className={statusClassMapping[glossary.status]}
            >
              {statusIconMapping[glossary.status]}
              <span className="align-middle ml-1">
                {t(`glossary.status.${glossary.status.toLowerCase()}`)}
              </span>
            </Typography>
          );

          if (
            glossary.status === GlossaryCollectionDtoStatusEnum.Error &&
            glossary.errorMessage
          ) {
            return <Tooltip title={glossary.errorMessage}>{content}</Tooltip>;
          }

          return content;
        },
        hidden: isSmallScreen,
      },
    ],
    [isSmallScreen, isMediumScreen, onNavigate],
  );

  const visibleColumns = columns.filter((column) => !column.hidden);

  return (
    <PaginatedTable
      dummyPagination
      columns={visibleColumns}
      data={filteredGlossaries}
      selectedItems={selectedGlossaries}
      page={page}
      rowsPerPage={rowsPerPage}
      loading={glossary.loading}
      getItemId={(glossary) => glossary.uuid}
      onSelectAllClick={handleSelectAllClick}
      onItemClick={handleClick}
      onPageChange={handlePageChange}
      onRowsPerPageChange={handleRowsPerPageChange}
    />
  );
};
