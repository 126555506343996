/*
Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.

Licensed under the Apache License, Version 2.0 (the "License").
You may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/
import {
  Paper,
  Typography,
  Button,
  Chip,
  Tooltip,
  Card,
  CardContent,
  CardActions,
} from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ViolatedRuleDto, ViolationDto } from '@ink-ai/insight-service-sdk';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { copyText } from '@ink-ai/portal/common/utils';
import { LoadingButton } from '@mui/lab';
import { ViewDiff } from '../../../../common/components/ai-assistant/Compose/components/ViewDiff';

interface CheckingResultProps {
  ruleCheckResult: ViolationDto;
  index: number;
  onClick: () => void;
  onResolve: (index: number) => void;
  onAccept: (index: number) => boolean | Promise<boolean>;
}

const getConfidenceLevel = (score: number) => {
  if (score >= 0.8) return { level: 'high', color: 'error.main' };
  if (score >= 0.5) return { level: 'moderate', color: 'warning.main' };
  return { level: 'low', color: 'info.main' };
};

const CheckingResult: React.FC<CheckingResultProps> = ({
  ruleCheckResult,
  index,
  onClick,
  onResolve,
  onAccept,
}) => {
  const { t } = useTranslation();
  const [expandedRule, setExpandedRule] =
    React.useState<ViolatedRuleDto | null>(null);
  const [expandedRuleId, setExpandedRuleId] = React.useState<number>(0);
  const [copyTooltip, setCopyTooltip] = React.useState(false);
  const [acceptTooltip, setAcceptTooltip] = React.useState(false);
  const [acceptLoading, setAcceptLoading] = React.useState(false);

  const handleAccept = async (index: number) => {
    setAcceptLoading(true);
    try {
      const succeeded = await onAccept(index);
      if (succeeded) {
        onResolve(index);
      } else {
        setAcceptTooltip(true);
        copyText(ruleCheckResult.fixedContent);
        setTimeout(() => setAcceptTooltip(false), 3000);
      }
    } catch (err) {
      console.error('Failed to accept violation: ', err);
    } finally {
      setAcceptLoading(false);
    }
  };

  const handleCopy = () => {
    try {
      copyText(ruleCheckResult.fixedContent);
      setCopyTooltip(true);
      setTimeout(() => setCopyTooltip(false), 1500);
    } catch (err) {
      console.error('Failed to copy text: ', err);
    }
  };

  const confidence = getConfidenceLevel(ruleCheckResult.score);

  return (
    <>
      <Card
        key={index}
        sx={{
          marginY: 2,
          borderRadius: 4,
        }}
        onClick={() => {
          onClick();
        }}
      >
        <CardContent sx={{ textAlign: 'left' }}>
          <Box
            sx={{
              fontSize: '12px',
              bgcolor: confidence.color,
              color: 'white',
              px: 1,
              py: 0.5,
              borderRadius: 4,
              display: 'inline-block',
              mb: 1,
            }}
          >
            {t(`inspect.checkingResult.confidence.${confidence.level}`)}
          </Box>

          <ViewDiff
            originalText={ruleCheckResult.originContent}
            generatedText={ruleCheckResult.fixedContent}
          />

          <Box>
            {ruleCheckResult.violatedRules?.map((item, index) => (
              <Chip
                key={item.id}
                size="small"
                icon={<InfoOutlinedIcon color="error" />}
                label={t('inspect.checkingResult.rule', { number: index + 1 })}
                onClick={() => {
                  if (expandedRule === item) {
                    setExpandedRule(null);
                    setExpandedRuleId(null);
                  } else {
                    setExpandedRule(item);
                    setExpandedRuleId(index + 1);
                  }
                }}
                sx={{
                  cursor: 'pointer',
                  bgcolor: 'white',
                  border: '1px solid #D32F2F',
                  color: '#D32F2F',
                  '&:hover': {
                    bgcolor: '#FDEDED',
                  },
                  mr: 1,
                }}
              />
            ))}
          </Box>
          {expandedRule && (
            <Box sx={{ mt: 1 }}>
              <Paper sx={{ p: 1, maxWidth: 300, bgcolor: '#FDEDED' }}>
                <Typography variant="body2" sx={{ mb: 1 }}>
                  <b>
                    {t('inspect.checkingResult.rule', {
                      number: expandedRuleId,
                    })}
                    :
                  </b>
                  <span style={{ textDecoration: 'underline' }}>
                    {expandedRule?.definition ?? ''}
                  </span>
                  {expandedRule?.reason ?? ''}
                </Typography>
              </Paper>
            </Box>
          )}
        </CardContent>
        <CardActions sx={{ display: 'flex', gap: 1, mt: 0 }}>
          <Tooltip
            open={acceptTooltip}
            title={t('inspect.checkingResult.acceptError')}
            placement="top"
            arrow
          >
            <LoadingButton
              loading={acceptLoading}
              variant="text"
              onClick={() => handleAccept(index)}
            >
              {t('inspect.checkingResult.accept')}
            </LoadingButton>
          </Tooltip>
          <Tooltip
            open={copyTooltip}
            title={t('inspect.checkingResult.copySuccess')}
            placement="top"
            arrow
          >
            <Button variant="text" onClick={handleCopy}>
              {t('inspect.checkingResult.copy')}
            </Button>
          </Tooltip>
          <Button
            variant="text"
            onClick={() => {
              onResolve(index);
            }}
          >
            {t('inspect.checkingResult.ignore')}
          </Button>
        </CardActions>
      </Card>
    </>
  );
};

export default CheckingResult;
