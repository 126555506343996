/*
Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.

Licensed under the Apache License, Version 2.0 (the "License").
You may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Link,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import {
  ReferenceStoreDto,
  CreateReferenceStoreResponseDtoStatusEnum,
} from '@ink-ai/insight-service-sdk';
import { formatDate } from '@ink-ai/portal/common/utils';
import { PaginatedTable } from '../../../common/components/paginated-table/PaginatedTable';
import { EllipsisCell } from '../../../common/components/paginated-table/EllipsisCell';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@ink-ai/portal/reducers';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import PendingOutlinedIcon from '@mui/icons-material/PendingOutlined';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import {
  listReferenceStores,
  statusClassMapping,
  statusStringMapping,
} from '@ink-ai/portal/reducers/reference-store';

export const statusIconMapping: Record<
  CreateReferenceStoreResponseDtoStatusEnum,
  React.ReactNode
> = {
  [CreateReferenceStoreResponseDtoStatusEnum.Importing]: (
    <PendingOutlinedIcon />
  ),
  [CreateReferenceStoreResponseDtoStatusEnum.Ready]: (
    <CheckCircleOutlineOutlinedIcon />
  ),
  [CreateReferenceStoreResponseDtoStatusEnum.Error]: (
    <ErrorOutlineOutlinedIcon />
  ),
};

interface ReferenceStoreTableProps {
  selectedReferenceStores: string[];
  searchTerm: string;
  onNavigate?: (referenceStore: ReferenceStoreDto) => void;
  page: number;
  onPageChange: (page: number) => void;
  onSelectedReferenceStoresChange: (selectedReferenceStores: string[]) => void;
  onTotalItemCountChange?: (total: number) => void;
  allowNonReadySelection?: boolean;
}

export const ReferenceStoreTable: React.FC<ReferenceStoreTableProps> = ({
  selectedReferenceStores,
  onSelectedReferenceStoresChange,
  searchTerm,
  onNavigate,
  page,
  onPageChange,
  allowNonReadySelection = true,
}) => {
  const dispatch = useDispatch<any>();
  const referenceStore = useSelector(
    (state: RootState) => state.referenceStore,
  );
  const formatter = new Intl.NumberFormat('en-US');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    dispatch(listReferenceStores());
  }, [dispatch]);

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const currentPageGlossaries = filteredReferenceStores.slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage,
      );
      const newSelecteds = currentPageGlossaries.map((g) => g.uuid);
      onSelectedReferenceStoresChange(newSelecteds);
    } else {
      onSelectedReferenceStoresChange([]);
    }
  };

  const handleClick = (uuid: string) => {
    const referenceStoreItem = referenceStore.referenceStores.find(
      (g) => g.uuid === uuid,
    );
    if (
      !allowNonReadySelection &&
      referenceStoreItem?.status !==
        CreateReferenceStoreResponseDtoStatusEnum.Ready
    ) {
      return;
    }
    const selectedIndex = selectedReferenceStores.indexOf(uuid);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedReferenceStores, uuid);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedReferenceStores.slice(1));
    } else if (selectedIndex === selectedReferenceStores.length - 1) {
      newSelected = newSelected.concat(selectedReferenceStores.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedReferenceStores.slice(0, selectedIndex),
        selectedReferenceStores.slice(selectedIndex + 1),
      );
    }

    onSelectedReferenceStoresChange(newSelected);
  };

  const handlePageChange = (
    _: React.ChangeEvent<HTMLInputElement>,
    newPage: number,
  ) => {
    onPageChange(newPage);
  };

  const handleRowsPerPageChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    onPageChange(0);
  };

  const filteredReferenceStores = useMemo(
    () =>
      (referenceStore.referenceStores || []).filter((g) =>
        g.name.toLowerCase().includes(searchTerm.toLowerCase()),
      ),
    [referenceStore.referenceStores, searchTerm],
  );

  const { t } = useTranslation();

  const columns = useMemo(
    () => [
      {
        id: 'name',
        label: t('referenceStore.table.name'),
        width: 150,
        render: (referenceStore: ReferenceStoreDto) => (
          <EllipsisCell
            content={
              <Link
                component="span"
                underline="hover"
                onClick={() => onNavigate && onNavigate(referenceStore)}
                style={{ cursor: 'pointer' }}
              >
                {referenceStore.name}
              </Link>
            }
            tooltip={referenceStore.name}
          />
        ),
      },
      {
        id: 'description',
        label: t('referenceStore.table.description'),
        width: 150,
        render: (referenceStore: ReferenceStoreDto) => (
          <EllipsisCell content={referenceStore.desc || '-'} />
        ),
        hidden: isSmallScreen,
      },
      {
        id: 'languages',
        label: t('referenceStore.table.languages'),
        width: 150,
        render: (referenceStore: ReferenceStoreDto) => (
          <EllipsisCell
            content={
              referenceStore.status ===
              CreateReferenceStoreResponseDtoStatusEnum.Ready
                ? referenceStore.languages.join(', ')
                : t('referenceStore.table.na')
            }
          />
        ),
      },
      {
        id: 'terms',
        label: t('referenceStore.table.terms'),
        render: (referenceStore: ReferenceStoreDto) =>
          referenceStore.status ===
          CreateReferenceStoreResponseDtoStatusEnum.Ready
            ? formatter.format(referenceStore.itemCount)
            : t('referenceStore.table.na'),
        hidden: isSmallScreen,
      },
      {
        id: 'updatedAt',
        label: t('referenceStore.table.updatedAt'),
        render: (referenceStore: ReferenceStoreDto) =>
          formatDate(referenceStore.updatedAt),
        width: 200,
        hidden: isMediumScreen,
      },
      {
        id: 'status',
        label: t('referenceStore.table.status'),
        render: (referenceStore: ReferenceStoreDto) => {
          const content = (
            <Typography
              variant="body2"
              className={statusClassMapping[referenceStore.status]}
            >
              {statusIconMapping[referenceStore.status]}
              <span className="align-middle ml-1">
                {t(statusStringMapping[referenceStore.status])}
              </span>
            </Typography>
          );

          if (
            referenceStore.status ===
              CreateReferenceStoreResponseDtoStatusEnum.Error &&
            referenceStore.errorMessage
          ) {
            return (
              <Tooltip title={referenceStore.errorMessage}>{content}</Tooltip>
            );
          }

          return content;
        },
        hidden: isSmallScreen,
      },
    ],
    [isSmallScreen, isMediumScreen, onNavigate],
  );

  const visibleColumns = columns.filter((column) => !column.hidden);

  return (
    <PaginatedTable
      dummyPagination
      columns={visibleColumns}
      data={filteredReferenceStores}
      selectedItems={selectedReferenceStores}
      page={page}
      rowsPerPage={rowsPerPage}
      loading={referenceStore.loading}
      getItemId={(referenceStore) => referenceStore.uuid}
      onSelectAllClick={handleSelectAllClick}
      onItemClick={handleClick}
      onPageChange={handlePageChange}
      onRowsPerPageChange={handleRowsPerPageChange}
    />
  );
};
