/*
Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.

Licensed under the Apache License, Version 2.0 (the "License").
You may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, TextField, Button, CardContent } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '@ink-ai/portal/reducers';
import {
  article,
  startArticleGeneration,
  transformConnectorData,
} from '@ink-ai/portal/reducers/article';
import { RoundedButton } from '@ink-ai/portal/common/components/Buttons';
import { StatusDisplay } from './StatusDisplay';
import StopIcon from '@mui/icons-material/Stop';
import AutoFixHighOutlinedIcon from '@mui/icons-material/AutoFixHighOutlined';
import { CollapseCard } from '../../cards/CollapseCard';
import ConnectorSelector from './components/ConnectorSelector';

export const ArticleGenerate = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch<any>();
  const articleState = useSelector((state: RootState) => state.article);
  const connectorState = useSelector(
    (state: RootState) => state.connectorFilter,
  );

  const { currentStep, steps, selectedConnectors, inputValue } = articleState;

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(article.actions.updateInputValue(event.target.value));
  };

  const handleGenerateClick = () => {
    const connectors = transformConnectorData(
      connectorState.selectedConnectors,
    );
    dispatch(
      startArticleGeneration({
        text: inputValue,
        connectors: connectors,
      }),
    );
    console.log('Generating with:', { inputValue, selectedConnectors });
  };

  return (
    <Box>
      <TextField
        fullWidth
        label={t('compose.inputLabel')}
        value={inputValue}
        onChange={handleInputChange}
        sx={{ mb: 2 }}
      />
      <ConnectorSelector />
      <div className="py-3">
        <RoundedButton
          variant="contained"
          fullWidth={true}
          size="large"
          onClick={handleGenerateClick}
          disabled={!inputValue || articleState.isGenerating}
        >
          <AutoFixHighOutlinedIcon className="mr-1" />
          {t('compose.composeButton')}
        </RoundedButton>
      </div>
      {articleState.steps.length > 0 && (
        <CollapseCard open={true}>
          <CardContent className="card-content">
            <div>
              <StatusDisplay steps={steps} currentStep={currentStep} />
            </div>
            <div>
              {articleState.isGenerating && (
                <Button
                  color="error"
                  fullWidth={true}
                  onClick={() => dispatch(article.actions.stopGenerating())}
                  sx={{ mt: 1 }}
                >
                  <StopIcon className="mr-1" />
                  {t('compose.stopButton')}
                </Button>
              )}
            </div>
          </CardContent>
        </CollapseCard>
      )}
    </Box>
  );
};
