/*
Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.

Licensed under the Apache License, Version 2.0 (the "License").
You may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/
import { Box, LinearProgress, Typography } from '@mui/material';
import React, { useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import PlayCircleFilledWhiteOutlinedIcon from '@mui/icons-material/PlayCircleFilledWhiteOutlined';
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';
import { refreshDocumentChunk } from '@ink-ai/portal/common/utils/document.util';
import { getApi } from '@ink-ai/portal/common/requestHelper';
import { FactCheckApi, WritingRuleApi } from '@ink-ai/insight-service-sdk';
import { useDispatch, useSelector } from 'react-redux';
import {
  inspectActions,
  InspectStatusType,
} from '@ink-ai/portal/reducers/inspect';
import { article } from '@ink-ai/portal/reducers/article';
import { RootState } from '@ink-ai/portal/reducers';
import { RoundedButton } from '@ink-ai/portal/common/components/Buttons';
import { useInspectTypeList } from '../Inspect';
import { cloneDeep } from 'lodash-es';

interface CheckInprogressProps {
  type: string;
  checkingStatus: InspectStatusType;
  updateStatus: (status: InspectStatusType) => void;
}

const CheckInprogress: React.FC<CheckInprogressProps> = ({
  type,
  checkingStatus,
  updateStatus,
}) => {
  const { t } = useTranslation();
  const commonState = useSelector((state: RootState) => state.common);
  const inspectState = useSelector((state: RootState) => state.inspect);
  const articleState = useSelector((state: RootState) => state.article);
  const authState = useSelector((state: RootState) => state.auth);
  const referenceState = useSelector((state: RootState) => state.reference);
  const inspectTypeList = useInspectTypeList();
  const dispatch = useDispatch();

  const checkText = useMemo(() => {
    return inspectTypeList.find((item) => item.value === type)?.label;
  }, [type]);

  // 用于存储检查状态的 ref
  const checkRef = useRef({
    documentChunkList: [],
    selectedText: '',
    currentIndex: 0,
    status: 'idle' as InspectStatusType,
  });

  const handlePauseCheck = () => {
    updateStatus('paused');
    checkRef.current.status = 'paused';
  };

  const handleContinueCheck = async () => {
    updateStatus('checking');
    checkRef.current.status = 'checking';
    if (type === 'rule') {
      await processChunksForRule();
    } else {
      await processChunksForFactCheck();
    }
  };

  const processChunksForRule = async () => {
    const { documentChunkList, currentIndex, selectedText } = checkRef.current;
    const total =
      inspectState.scanType === 'select' ? 1 : documentChunkList.length;

    dispatch(inspectActions.setTotalRuleCheckingTask(total));

    const writingRuleApi = await getApi(WritingRuleApi);

    if (inspectState.scanType === 'select') {
      const response = await writingRuleApi.check({
        payload: {
          text: selectedText,
          rules: inspectState.selectedRules,
          chunkType: 'PARAGRAPH',
        },
        instanceId: authState.instanceId,
      });
      dispatch(inspectActions.submittedRuleCheckTask());
      dispatch(
        inspectActions.updateTaskIndexMap({
          taskId: (response.data as any).id,
          index: 0,
        }),
      );
    } else {
      for (let i = currentIndex; i < total; i++) {
        if (checkRef.current.status === 'paused') {
          checkRef.current.currentIndex = i;
          return;
        }

        const markdownText = await documentChunkList[i].toMarkdown();
        const response = await writingRuleApi.check({
          payload: {
            text: markdownText,
            rules: inspectState.selectedRules,
            chunkType: documentChunkList[i].type,
          },
          instanceId: authState.instanceId,
        });
        dispatch(inspectActions.submittedRuleCheckTask());

        dispatch(
          inspectActions.updateTaskIndexMap({
            taskId: (response.data as any).id,
            index: i,
          }),
        );
        checkRef.current.currentIndex = i + 1;
      }
    }

    if (checkRef.current.currentIndex >= total) {
      checkRef.current = {
        documentChunkList: [],
        selectedText: '',
        currentIndex: 0,
        status: 'idle',
      };
    }
  };

  const processChunksForFactCheck = async () => {
    const { documentChunkList, currentIndex } = checkRef.current;
    const total =
      inspectState.scanType === 'select' ? 1 : documentChunkList.length;

    dispatch(inspectActions.setTotalFactCheckingTask(total));

    const factCheckApi = await getApi(FactCheckApi);

    const citations = cloneDeep(articleState.citations);

    if (inspectState.useAdditionalSource) {
      const existFileList = citations.map((item) => item.source);
      const checkedFileList = referenceState.fileList.filter(
        (file) => file.checked && !existFileList.includes(file.id),
      );
      checkedFileList.forEach((item, index) => {
        citations.push({
          id: citations.length + index + 1,
          text: item.name,
          title: item.name,
          source: item.id,
          checked: true,
          connector: {
            name: 'File Connector',
            type: 'file',
            functionName: 'N/A',
          },
        });
      });
    }

    if (inspectState.scanType === 'select') {
      const response = await factCheckApi.factCheck({
        payload: {
          text: checkRef.current.selectedText,
          citations: citations,
        },
        instanceId: authState.instanceId,
      });
      dispatch(inspectActions.submittedFactCheckTask());
      dispatch(
        inspectActions.updateFactTaskIndexMap({
          taskId: (response.data as any).id,
          index: 0,
        }),
      );
    } else {
      for (let i = currentIndex; i < total; i++) {
        if (checkRef.current.status === 'paused') {
          checkRef.current.currentIndex = i;
          return;
        }
        const markdownText = await documentChunkList[i].toMarkdown();

        const response = await factCheckApi.factCheck({
          payload: {
            text: markdownText,
            citations: citations,
          },
          instanceId: authState.instanceId,
        });
        dispatch(inspectActions.submittedFactCheckTask());
        dispatch(
          inspectActions.updateFactTaskIndexMap({
            taskId: (response.data as any).id,
            index: i,
          }),
        );
        checkRef.current.currentIndex = i + 1;
      }
    }

    // update citations
    dispatch(article.actions.setCitations(citations));

    if (checkRef.current.currentIndex >= total) {
      checkRef.current = {
        documentChunkList: [],
        selectedText: '',
        currentIndex: 0,
        status: 'idle',
      };
    }
  };

  const handleStartCheck = async () => {
    if (type === 'rule' && inspectState.selectedRules.length === 0) {
      dispatch(inspectActions.changeRuleEmptyError(true));
      return;
    }
    if (
      inspectState.scanType === 'select' &&
      commonState.selectedMarkdown.length <= 0
    ) {
      dispatch(inspectActions.setInputContentEmptyError(true));
      return;
    }

    updateStatus('checking');
    // setProgress(0);
    if (type === 'rule') {
      dispatch(inspectActions.resetRuleCheckResult());
    } else {
      dispatch(inspectActions.resetFactCheckResult());
    }

    const documentChunkList = await refreshDocumentChunk();
    checkRef.current = {
      documentChunkList,
      selectedText: commonState.selectedMarkdown,
      currentIndex: 0,
      status: 'checking',
    };

    if (type === 'rule') {
      await processChunksForRule();
    } else {
      await processChunksForFactCheck();
    }
  };

  const ruleCheckProgress = useMemo(() => {
    const submittedPercent = Math.round(
      (inspectState.submittedRuleCheckingTask /
        inspectState.totalRuleCheckingTask) *
        100,
    );
    const completedPercent = Math.round(
      (inspectState.completedRuleCheckingTask /
        inspectState.totalRuleCheckingTask) *
        100,
    );
    return Math.round(submittedPercent * 0.3 + completedPercent * 0.7);
  }, [
    inspectState.submittedRuleCheckingTask,
    inspectState.totalRuleCheckingTask,
    inspectState.completedRuleCheckingTask,
  ]);

  const factCheckProgress = useMemo(() => {
    const submittedPercent = Math.round(
      (inspectState.submittedFactCheckingTask /
        inspectState.totalFactCheckingTask) *
        100,
    );
    const completedPercent = Math.round(
      (inspectState.completedFactCheckingTask /
        inspectState.totalFactCheckingTask) *
        100,
    );
    return Math.round(submittedPercent * 0.3 + completedPercent * 0.7);
  }, [
    inspectState.submittedFactCheckingTask,
    inspectState.totalFactCheckingTask,
    inspectState.completedFactCheckingTask,
  ]);

  useEffect(() => {
    if (
      inspectState.scanType === 'selected' &&
      commonState.selectedMarkdown.length <= 0
    ) {
      dispatch(inspectActions.setInputContentEmptyError(true));
    } else {
      dispatch(inspectActions.setInputContentEmptyError(false));
    }
  }, [commonState.selectedMarkdown]);

  return (
    <Box sx={{ px: 2, mt: 1 }}>
      {checkingStatus === 'idle' && (
        <RoundedButton
          variant="contained"
          fullWidth
          startIcon={<PlayCircleFilledWhiteOutlinedIcon />}
          onClick={handleStartCheck}
          sx={{ mb: 2 }}
        >
          {t('inspect.progress.start', { type: checkText })}
        </RoundedButton>
      )}

      {(checkingStatus === 'checking' || checkingStatus === 'paused') && (
        <>
          <Typography variant="body1" sx={{ mb: 0, textAlign: 'left' }}>
            {t('inspect.progress.validating')}
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 0 }}>
            {type === 'rule' && (
              <>
                <LinearProgress
                  variant="determinate"
                  value={ruleCheckProgress}
                  sx={{ flex: 1 }}
                />
                <Typography variant="body2" sx={{ minWidth: '40px' }}>
                  {`${ruleCheckProgress}%`}
                </Typography>
              </>
            )}
            {type === 'fact' && (
              <>
                <LinearProgress
                  variant="determinate"
                  value={factCheckProgress}
                  sx={{ flex: 1 }}
                />
                <Typography variant="body2" sx={{ minWidth: '40px' }}>
                  {`${factCheckProgress}%`}
                </Typography>
              </>
            )}
          </Box>
          <RoundedButton
            variant="outlined"
            fullWidth
            startIcon={
              checkingStatus === 'checking' ? (
                <PauseCircleOutlineIcon />
              ) : (
                <PlayCircleFilledWhiteOutlinedIcon />
              )
            }
            onClick={
              checkingStatus === 'checking'
                ? handlePauseCheck
                : handleContinueCheck
            }
            sx={{ mb: 2 }}
          >
            {checkingStatus === 'checking'
              ? t('inspect.progress.pause', { type: checkText })
              : t('inspect.progress.continue', { type: checkText })}
          </RoundedButton>
        </>
      )}
    </Box>
  );
};

export default CheckInprogress;
