/*
Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.

Licensed under the Apache License, Version 2.0 (the "License").
You may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  ToggleButton,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Radio,
  RadioGroup,
  FormControlLabel,
  OutlinedInput,
  Chip,
  LinearProgress,
  FormHelperText,
  Switch,
} from '@mui/material';
import CheckInprogress from './comps/CheckInprogress';
import CheckingResult from './comps/CheckingResult';
import { RootState } from '@ink-ai/portal/reducers';
import { useSelector, useDispatch } from 'react-redux';
import { InspectState, inspectActions } from '@ink-ai/portal/reducers/inspect';
import { WritingRuleApi } from '@ink-ai/insight-service-sdk';
import { getApi } from '@ink-ai/portal/common/requestHelper';
import { useAsyncData } from '@ink-ai/portal/common/hooks/useAsyncData';
import { RoundToggleButtonGroup } from '@ink-ai/portal/common/components/Buttons';
import FactCheckingResult from './comps/FactCheckingResult';
import FactFactualResult from './comps/FactFactualResult';
import { UploadFileComponent } from '@ink-ai/portal/common/components/ai-assistant/Compose/UploadFile';
import StickyContainer from './comps/StickyContainer';
import { getDocumentChunkList } from '@ink-ai/portal/common/utils/document.util';
import { InputContent } from '@ink-ai/portal/common/components/ai-assistant/Compose/InputContent';

export const useInspectTypeList = () => {
  const { t } = useTranslation();
  return [
    {
      label: t('inspect.types.ruleChecking'),
      value: 'rule',
    },
    {
      label: t('inspect.types.factChecking'),
      value: 'fact',
    },
  ];
};

const Inspect = () => {
  const { t } = useTranslation();
  const inspectState = useSelector((state: RootState) => state.inspect);
  const dispatch = useDispatch<any>();

  const InspectTypeList = useInspectTypeList();

  const { data, isLoadingData } = useAsyncData(async () => {
    const writingRuleApi = await getApi(WritingRuleApi);
    return await writingRuleApi.query({
      pagination: {
        take: 9999,
      },
    });
  }, []);

  const onRuleCheckResClick = async (
    ruleCheckResult: InspectState['ruleCheckResult'][number],
  ) => {
    dispatch(inspectActions.setCurrentChunkTaskId(ruleCheckResult.chunkTaskId));
    const currentChunkIndex =
      inspectState.taskIndexMap[ruleCheckResult.chunkTaskId];
    const documentChunkList = getDocumentChunkList();
    const chunk = documentChunkList[currentChunkIndex];
    if (chunk) {
      const range = await chunk?.findText(ruleCheckResult.result.originContent);
      if (!range) {
        chunk?.focus();
      }
    }
  };

  const onRuleCheckResAccept = async (
    ruleCheckResult: InspectState['ruleCheckResult'][number],
  ) => {
    const currentChunkIndex =
      inspectState.taskIndexMap[ruleCheckResult.chunkTaskId];
    const documentChunkList = getDocumentChunkList();
    const chunk = documentChunkList[currentChunkIndex];
    if (chunk) {
      return await chunk.replaceText(
        ruleCheckResult.result.originContent,
        ruleCheckResult.result.fixedContent,
      );
    }
    return false;
  };

  const onFactCheckViolationResClick = async (
    res: InspectState['factCheckFactualResult'][number],
    isViolation?: boolean,
  ) => {
    dispatch(
      isViolation
        ? inspectActions.setCurrentFactChunkTaskId(res.chunkTaskId)
        : inspectActions.setCurrentFactChunkTaskId(res.chunkTaskId),
    );
    const currentChunkIndex =
      inspectState.currentFactTaskIndexMap[res.chunkTaskId];
    const documentChunkList = getDocumentChunkList();
    const chunk = documentChunkList[currentChunkIndex];
    if (chunk) {
      const range = await chunk?.findText(res.result.originalSentence);
      if (!range) {
        chunk?.focus();
      }
    }
  };

  const onFactCheckViolationAccept = async (
    res: InspectState['factCheckViolationResult'][number],
  ) => {
    const currentChunkIndex =
      inspectState.currentFactTaskIndexMap[res.chunkTaskId];
    const documentChunkList = getDocumentChunkList();
    const chunk = documentChunkList[currentChunkIndex];
    if (chunk) {
      return await chunk.replaceText(
        res.result.originalSentence,
        res.result.fixedSentence,
      );
    }
    return false;
  };

  if (isLoadingData) {
    return (
      <Box sx={{ p: 2 }}>
        <LinearProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ p: 0, overflow: 'auto' }}>
      {/* Toggle Buttons for Rule/Fact Checking */}
      <RoundToggleButtonGroup
        size="small"
        value={inspectState.inspectType}
        exclusive
        onChange={(_, newType) => {
          dispatch(inspectActions.changeInspectType(newType));
        }}
        sx={{ p: 2, mb: 0, width: '100%' }}
      >
        {InspectTypeList.map((type) => (
          <ToggleButton
            key={type.value}
            value={type.value}
            sx={{
              flex: 1,
              '&.Mui-selected': {
                bgcolor: 'primary.main',
                color: 'primary.contrastText',
                '&:hover': {
                  bgcolor: 'primary.dark',
                },
              },
            }}
          >
            {type.label}
          </ToggleButton>
        ))}
      </RoundToggleButtonGroup>

      {/* Validation Rules Select */}
      {inspectState.inspectType === 'rule' && (
        <Box sx={{ px: 2 }}>
          <FormControl
            fullWidth
            sx={{ mt: 0 }}
            error={inspectState.ruleEmptyError}
          >
            <InputLabel sx={{ mt: 0, p: 0 }} id="ruleList">
              {t('inspect.form.selectRules.label')}
            </InputLabel>
            <Select
              labelId="ruleList"
              id="ruleList"
              multiple
              value={inspectState.selectedRules}
              onChange={(e) => {
                dispatch(
                  inspectActions.changeSelectedRules(
                    e.target.value as string[],
                  ),
                );
              }}
              input={
                <OutlinedInput label={t('inspect.form.selectRules.label')} />
              }
              renderValue={(selected: string[]) => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {selected.map((item) => {
                    const rule = data.data?.data?.find(
                      (p) => p.id.toString() === item.toString(),
                    );
                    return (
                      <Chip
                        key={item}
                        size="small"
                        label={rule?.title}
                        onMouseDown={(e) => {
                          e.stopPropagation(); // 阻止 Select 的下拉框打开
                        }}
                        onDelete={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          const newSelected = selected.filter(
                            (id) => id.toString() !== item.toString(),
                          );
                          dispatch(
                            inspectActions.changeSelectedRules(newSelected),
                          );
                        }}
                      />
                    );
                  })}
                </Box>
              )}
            >
              {data.data?.data?.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.title}
                </MenuItem>
              ))}
            </Select>
            {inspectState.ruleEmptyError && (
              <FormHelperText>
                {t('inspect.form.selectRules.error')}
              </FormHelperText>
            )}
          </FormControl>
        </Box>
      )}

      {/* Input Type Selection */}
      <RadioGroup
        value={inspectState.scanType}
        onChange={(e) =>
          dispatch(inspectActions.changeScanType(e.target.value))
        }
        row
        sx={{ px: 2, mb: 1, mt: 1 }}
      >
        <FormControlLabel
          value="entire"
          control={<Radio />}
          label={t('inspect.form.scanType.entireArticle')}
        />
        <FormControlLabel
          value="select"
          control={<Radio />}
          label={t('inspect.form.scanType.selectText')}
        />
      </RadioGroup>

      {inspectState.scanType === 'select' && (
        <Box sx={{ px: 2, textAlign: 'left' }}>
          <InputContent placeholder={t('inspect.form.inputPlaceholder')} />
        </Box>
      )}

      {inspectState.inspectType === 'fact' && (
        <Box sx={{ px: 2, mt: 1 }}>
          <FormControlLabel
            control={
              <Switch
                checked={inspectState.useAdditionalSource}
                onChange={(e) => {
                  dispatch(
                    inspectActions.setUseAdditionalSource(e.target.checked),
                  );
                }}
              />
            }
            label={t('inspect.form.useAdditionalSource')}
          />
          {inspectState.useAdditionalSource && (
            <UploadFileComponent
              acceptedFileTypes={['.docx', '.xlsx', '.pdf']}
            />
          )}
        </Box>
      )}

      {/* Results Section */}
      {inspectState.inspectType === 'rule' ? (
        <CheckInprogress
          type="rule"
          checkingStatus={inspectState.ruleCheckingStatus}
          updateStatus={(status) => {
            dispatch(inspectActions.setRuleCheckingStatus(status));
          }}
        />
      ) : (
        <CheckInprogress
          type="fact"
          checkingStatus={inspectState.factCheckingStatus}
          updateStatus={(status) => {
            dispatch(inspectActions.setFactCheckingStatus(status));
          }}
        />
      )}

      {inspectState.inspectType === 'rule' &&
        inspectState.ruleCheckResult.length > 0 && (
          <StickyContainer
            title={t('inspect.results.nonComplaintCount')}
            count={inspectState.totalViolationCount}
            type="red"
          >
            {inspectState.ruleCheckResult.map((ruleCheckResult, index) => (
              <CheckingResult
                key={`${ruleCheckResult.result.originContent}${ruleCheckResult.result.fixedContent}`}
                index={index}
                ruleCheckResult={ruleCheckResult.result}
                onClick={() => onRuleCheckResClick(ruleCheckResult)}
                onResolve={(index) => {
                  dispatch(inspectActions.resolveRuleCheckResult(index));
                }}
                onAccept={() => onRuleCheckResAccept(ruleCheckResult)}
              />
            ))}
          </StickyContainer>
        )}

      {inspectState.inspectType === 'fact' &&
        inspectState.factCheckViolationResult.length > 0 && (
          <StickyContainer
            title={t('inspect.results.riskCount')}
            count={inspectState.totalFactViolationCount}
            type="red"
          >
            {inspectState.factCheckViolationResult.map(
              (factCheckViolationResult, index) => (
                <FactCheckingResult
                  key={`${factCheckViolationResult.result.originalSentence}${factCheckViolationResult.result.fixedSentence}`}
                  index={index}
                  factCheckViolationResult={factCheckViolationResult.result}
                  onClick={() =>
                    onFactCheckViolationResClick(factCheckViolationResult, true)
                  }
                  onResolve={(index) => {
                    dispatch(inspectActions.resolveFactCheckResult(index));
                  }}
                  onAccept={() =>
                    onFactCheckViolationAccept(factCheckViolationResult)
                  }
                />
              ),
            )}
          </StickyContainer>
        )}

      {inspectState.inspectType === 'fact' &&
        inspectState.factCheckFactualResult.length > 0 && (
          <StickyContainer
            title={t('inspect.results.factualCount')}
            count={inspectState.totalFactFactualCount}
            type="green"
          >
            {inspectState.factCheckFactualResult.map(
              (factCheckFactualResult, index) => (
                <FactFactualResult
                  key={`${factCheckFactualResult.result.originalSentence}`}
                  index={index}
                  factCheckFactualResult={factCheckFactualResult.result}
                  onClick={() =>
                    onFactCheckViolationResClick(factCheckFactualResult, false)
                  }
                />
              ),
            )}
          </StickyContainer>
        )}
    </Box>
  );
};

export default Inspect;
