/*
Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.

Licensed under the Apache License, Version 2.0 (the "License").
You may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/
import React, { useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { RootState } from '../../../reducers';
import { chat, createChat } from '../../../reducers/chat';
import { useDispatch, useSelector } from 'react-redux';

import { Paper, IconButton, InputBase, Tooltip } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import RefreshIcon from '@mui/icons-material/Refresh';
import { ChatBlock } from './ChatBlock';
import { ClickstreamAnalytics } from '@aws/clickstream-web';
import debounce from 'lodash-es/debounce';
import classNames from 'classnames';

export interface ChatProps {
  isOffice?: boolean;
}

export const ChatUI = ({ isOffice }: ChatProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch<any>();
  const chatState = useSelector((state: RootState) => state.chat);
  const commonState = useSelector((state: RootState) => state.common);

  useEffect(() => {
    if (chatState.messageList.length === 0) {
      dispatch(chat.actions.clearAll());
      dispatch(chat.actions.addWelcomeMessage());
    }
  }, []);

  useEffect(() => {
    dispatch(chat.actions.setSelectedText(commonState.selectedText));
  }, [commonState.selectedText, dispatch]);

  const handleStartNewChatClick = () => {
    dispatch(chat.actions.startNewChat());
    dispatch(chat.actions.setSelectedText(commonState.selectedText));
    ClickstreamAnalytics.record({
      name: 'start_new_chat_click',
    });
  };

  const handleSendClick = () => {
    dispatch(createChat());
    ClickstreamAnalytics.record({
      name: 'send_chat_click',
    });
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (chatState.loading) {
      return;
    }
    if (e.key === 'ArrowUp') {
      if (chatState.inputText === '') {
        dispatch(chat.actions.startSearchHistory());
        dispatch(chat.actions.searchUp());
      } else if (chatState.isSearchingHistory) {
        dispatch(chat.actions.searchUp());
      }
      ClickstreamAnalytics.record({
        name: 'search_chat_history_key',
      });
    }
    if (e.key === 'ArrowDown') {
      if (chatState.isSearchingHistory) {
        dispatch(chat.actions.searchDown());
      }
      ClickstreamAnalytics.record({
        name: 'search_chat_history_key',
      });
    }
  };

  const handleSubmit = (e: React.KeyboardEvent<HTMLFormElement>) => {
    e.preventDefault();
    dispatch(createChat());

    ClickstreamAnalytics.record({
      name: 'submit_chat_enter_key',
    });
  };
  const messagesEndRef = useRef<HTMLDivElement>(null);

  const scrollToTarget = useMemo(
    () =>
      debounce(() => {
        if (messagesEndRef.current) {
          console.log(messagesEndRef.current.scrollHeight);
          messagesEndRef.current.scrollTop =
            messagesEndRef.current.scrollHeight;
        }
      }, 500),
    [messagesEndRef.current],
  );

  useEffect(scrollToTarget, [chatState.messageList]);
  return (
    <div
      className={`flex flex-col h-screen overflow-y-auto ${classNames({
        'pt-20 pb-12': isOffice,
      })}`}
      ref={messagesEndRef}
    >
      <ChatBlock messages={chatState.messageList} />
      <Paper
        component="form"
        sx={{
          p: '2px 4px',
          display: 'flex',
          alignItems: 'center',
          left: '5px',
          right: '5px',
          height: '35px',
          borderRadius: '10px',
          border: `2px solid ${chatState.loading ? '#bdbdbd' : '#5F6B7A'}`, // Change border color when disabled
          boxShadow: 'none',
        }}
        className={`absolute bottom-0 ${classNames({
          'mb-12': isOffice,
        })}`}
        onSubmit={handleSubmit}
      >
        <Tooltip title={t('chat.startNewChat')}>
          <IconButton
            type="button"
            sx={{ p: '2px' }}
            aria-label={t('chat.startNewChat')}
            onClick={handleStartNewChatClick}
          >
            <RefreshIcon />
          </IconButton>
        </Tooltip>
        <InputBase
          sx={{
            ml: 1,
            flex: 1,
            '& input::placeholder': {
              fontStyle: 'italic',
            },
          }}
          autoFocus={true}
          placeholder={t('chat.inputPlaceholder')}
          value={chatState.inputText}
          inputProps={{ 'aria-label': t('chat.inputPlaceholder') }}
          disabled={chatState.loading}
          onChange={(e) => {
            dispatch(chat.actions.setInputText(e.target.value));
            dispatch(chat.actions.resetSearchHistory());
          }}
          onKeyDown={handleKeyPress}
        />
        <IconButton
          type="button"
          sx={{ p: '2px' }}
          aria-label={t('chat.send')}
          onClick={handleSendClick}
        >
          <SendIcon />
        </IconButton>
      </Paper>
    </div>
  );
};
