/*
Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.

Licensed under the Apache License, Version 2.0 (the "License").
You may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Chip, InputLabel } from '@mui/material';
import InstructionInlineEditor from './InstructionInlineEditor';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@ink-ai/portal/reducers';
import { composeInstructionActions } from '@ink-ai/portal/reducers/compose-instruction';
import { identity } from 'lodash-es';

interface DynamicTextRendererProps {
  text: string;
}

const RenderInstructionField: React.FC<DynamicTextRendererProps> = ({
  text,
}) => {
  const { t } = useTranslation();
  const composeInstructionState = useSelector(
    (state: RootState) => state.composeInstruction,
  );
  const dispatch = useDispatch();
  const regex = /{([^}]+)}/g;
  const parts = text.split(regex);

  return (
    <Box>
      <InputLabel
        shrink
        sx={{
          marginTop: 2,
          color: '#757575',
          pointerEvents: 'none',
        }}
      >
        {t('compose.instruction.label')}
      </InputLabel>
      <Box
        sx={{
          gap: '2px',
          display: 'inline-flex',
          alignItems: 'center',
          flexWrap: 'wrap',
          lineHeight: '1.8em',
        }}
      >
        {parts.map((part, index) =>
          index % 2 === 1 ? (
            <InstructionInlineEditor
              key={identity(index)}
              placeholder={`{${part}}`}
              value={
                composeInstructionState?.userInstructionInputObj?.[part] || ''
              }
              onChange={(newValue) => {
                dispatch(
                  composeInstructionActions.changeUserInstructionTemplateInput({
                    key: part,
                    value: newValue,
                  }),
                );
              }}
            />
          ) : (
            <span key={identity(index)}>{part}</span>
          ),
        )}
      </Box>
      <Box
        sx={{
          marginTop: '2px',
          height: '1px',
          width: '100%',
          backgroundColor: '#ccc',
        }}
      ></Box>
      <Box display="inline-flex" mt="4px">
        <Chip
          size="small"
          label={`${composeInstructionState.currentCharacterCount}/1000`}
          variant="outlined"
        />
      </Box>
    </Box>
  );
};

export default RenderInstructionField;
