/*
Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.

Licensed under the Apache License, Version 2.0 (the "License").
You may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  FormControl,
  TextField,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Paper,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FactCheckOutlinedIcon from '@mui/icons-material/FactCheckOutlined';
import SmallSelectWithLabel from './SmallSelectWithLabel';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@ink-ai/portal/reducers';
import {
  connectorFilterActions,
  SelectedConnectors,
} from '@ink-ai/portal/reducers/connector-filter';
import FilterSelectWithLabel from './FilterSelectWithLabel';
import BasicDatePicker from './BasicDatePicker';
import DateRangePickerComponent from './DateRangePicker';
import { identity } from 'lodash-es';
import SelectedFilterChip from './SelectedFilterChip';
import dayjs from 'dayjs';

interface ConnectorFilterComponentProps {
  connectorIndex: number;
  connectorWithFilter: SelectedConnectors;
}

const ConnectorFilterComponent: React.FC<ConnectorFilterComponentProps> = (
  props: ConnectorFilterComponentProps,
) => {
  const { t } = useTranslation();
  const connectorFilterState = useSelector(
    (state: RootState) => state.connectorFilter,
  );
  const { connectorIndex, connectorWithFilter } = props;
  const dispatch = useDispatch();

  const getFilterOptions = () => {
    return (
      connectorWithFilter?.connector?.filters?.find(
        (item) =>
          item.field_name === connectorWithFilter?.newFilterFiled?.field_name,
      )?.options ?? []
    );
  };

  const renderInputValue = () => {
    if (
      connectorWithFilter?.newFilterFiled?.type === 'STRING' ||
      connectorWithFilter?.newFilterFiled?.type === 'NUMBER'
    ) {
      return getFilterOptions().length > 0 ? (
        <FilterSelectWithLabel
          selectLabel={t('connectors.filter.operator')}
          optionList={getFilterOptions()}
          selectedItem={connectorWithFilter.newFilterSelectionValue}
          getLabel={(item) => item.label}
          getValue={(item) => item.value}
          handleSelectChange={(item) => {
            dispatch(
              connectorFilterActions.changeNewFilterSelectionValue({
                index: connectorIndex,
                value: item,
              }),
            );
          }}
        />
      ) : (
        <TextField
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          variant="standard"
          size="small"
          label={t('connectors.filter.filterValue')}
          value={connectorWithFilter.newFilterStringValue}
          onChange={(e) => {
            dispatch(
              connectorFilterActions.changeNewFilterStringValue({
                index: connectorIndex,
                stringValue: e.target.value,
              }),
            );
          }}
        />
      );
    }

    if (connectorWithFilter?.newFilterFiled?.type === 'DATETIME') {
      return (
        <BasicDatePicker
          value={dayjs(connectorWithFilter.newFilterDateValue)}
          onChange={(e) => {
            dispatch(
              connectorFilterActions.changeNewFilterDateValue({
                index: connectorIndex,
                dateValue: dayjs(e).valueOf(),
              }),
            );
          }}
        />
      );
    }

    if (connectorWithFilter?.newFilterFiled?.type === 'DATE_RANGE') {
      return (
        <DateRangePickerComponent
          startDate={dayjs(connectorWithFilter.newFilterDateRangeStartValue)}
          changeStartDate={(e) => {
            dispatch(
              connectorFilterActions.changeNewFilterDateRangeStartValue({
                index: connectorIndex,
                dateValue: dayjs(e).valueOf(),
              }),
            );
          }}
          endDate={dayjs(connectorWithFilter.newFilterDateRangeEndValue)}
          changeEndDate={(e) => {
            dispatch(
              connectorFilterActions.changeNewFilterDateRangeEndValue({
                index: connectorIndex,
                dateValue: dayjs(e).valueOf(),
              }),
            );
          }}
        />
      );
    }

    return (
      <TextField
        fullWidth
        disabled
        InputLabelProps={{
          shrink: true,
        }}
        variant="standard"
        size="small"
        label={t('connectors.filter.filterValue')}
      />
    );
  };

  return (
    <Accordion defaultExpanded>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon sx={{ color: '#2196F3' }} />}
        aria-controls="panel1-content"
        id="panel1-header"
        sx={{
          backgroundColor: '#E4F2FD',
          color: '#2196F3',
          height: 48,
          minHeight: 48,
          '&.Mui-expanded': {
            height: 48,
            minHeight: 48,
          },
        }}
      >
        <FactCheckOutlinedIcon sx={{ mr: 1 }} />
        {t('connectors.filter.title')}
      </AccordionSummary>
      <AccordionDetails>
        {/* 选择 Connector */}
        <FormControl fullWidth>
          <SmallSelectWithLabel
            selectLabel={t('connectors.filter.selectConnector')}
            optionList={connectorFilterState.connectorsList ?? []}
            handleSelectChange={(connector) => {
              dispatch(
                connectorFilterActions.changeCurrentConnector({
                  index: connectorIndex,
                  connector,
                }),
              );
            }}
            getLabel={(item) => item.name}
            getValue={(item) => item.id}
            selectedItem={connectorWithFilter.connector}
          />
        </FormControl>
        {connectorWithFilter.connector && (
          <>
            {connectorWithFilter.showAddFilterDialog && (
              <Paper
                sx={{
                  p: '10px',
                  position: 'absolute',
                  backgroundColor: '#fff',
                  zIndex: 99,
                  width: '90%',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  mt: 1,
                  gap: '10px',
                }}
              >
                {/* 选择 Filter */}
                <FilterSelectWithLabel
                  selectLabel={t('connectors.filter.fieldColumn')}
                  optionList={connectorWithFilter?.connector?.filters ?? []}
                  selectedItem={connectorWithFilter.newFilterFiled}
                  getLabel={(item) => item.display_name}
                  getValue={(item) => item.field_name}
                  handleSelectChange={(item) => {
                    dispatch(
                      connectorFilterActions.changeNewFilterField({
                        index: connectorIndex,
                        filter: item,
                      }),
                    );
                  }}
                />

                {/* 选择操作符 */}
                <FilterSelectWithLabel
                  disabled={!connectorWithFilter.newFilterFiled}
                  selectLabel={t('connectors.filter.operator')}
                  optionList={[
                    {
                      label: t('connectors.filter.operators.equal'),
                      value: 'EQUAL',
                    },
                    {
                      label: t('connectors.filter.operators.notEqual'),
                      value: 'NOT',
                    },
                  ]}
                  selectedItem={connectorWithFilter.newFilterOperator}
                  getLabel={(item) => item.label}
                  getValue={(item) => item.value}
                  handleSelectChange={(item) => {
                    dispatch(
                      connectorFilterActions.changeNewFilterOperator({
                        index: connectorIndex,
                        operator: item,
                      }),
                    );
                  }}
                />

                {/* 输入值 */}
                {renderInputValue()}

                <Box
                  display="flex"
                  sx={{
                    width: '100%',
                    mt: 1,
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    gap: '10px',
                  }}
                >
                  <Button
                    size="small"
                    onClick={() => {
                      dispatch(
                        connectorFilterActions.showAddFilterDialog({
                          index: connectorIndex,
                          show: false,
                        }),
                      );
                    }}
                  >
                    {t('connectors.filter.buttons.cancel')}
                  </Button>
                  <Button
                    size="small"
                    variant="contained"
                    onClick={() => {
                      dispatch(
                        connectorFilterActions.addConnectorFilter({
                          index: connectorIndex,
                        }),
                      );
                    }}
                  >
                    {t('connectors.filter.buttons.add')}
                  </Button>
                </Box>
              </Paper>
            )}

            <Box
              sx={{ display: 'flex', justifyContent: 'space-between', mt: 1 }}
            >
              <Button
                size="small"
                variant="outlined"
                onClick={() => {
                  dispatch(
                    connectorFilterActions.showAddFilterDialog({
                      index: connectorIndex,
                      show: true,
                    }),
                  );
                }}
              >
                {t('connectors.filter.buttons.addFilter')}
              </Button>
              <TextField
                InputLabelProps={{
                  shrink: true,
                  sx: {
                    fontSize: '14px',
                  },
                }}
                value={connectorWithFilter.maxResults}
                onChange={(e) => {
                  dispatch(
                    connectorFilterActions.changeMaxResults({
                      index: connectorIndex,
                      maxResults: e.target.value,
                    }),
                  );
                }}
                InputProps={{
                  sx: {
                    width: '68px',
                    height: '20px',
                    fontSize: '14px',
                    lineHeight: '20px',
                    '& input': {
                      textAlign: 'right',
                    },
                  },
                }}
                variant="standard"
                size="small"
                label={t('connectors.filter.maxRetrieval')}
              />
            </Box>
          </>
        )}
        {/* selected filters */}
        <Box
          sx={{
            mt: connectorWithFilter.selectedFilters.length > 0 ? 2 : 0,
            display: 'flex',
            flexWrap: 'wrap',
            gap: '5px',
          }}
        >
          {connectorWithFilter.selectedFilters.map((item, index) => {
            return (
              <SelectedFilterChip
                item={item}
                connectorIndex={connectorIndex}
                filterIndex={index}
                key={identity(index)}
              />
            );
          })}
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default ConnectorFilterComponent;
