/*
Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.

Licensed under the Apache License, Version 2.0 (the "License").
You may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/
import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import ConnectorSelector from './components/ConnectorSelector';
import InstructionInput from './components/InstructionInput';
import {
  article,
  startInstructionContentGeneration,
} from '@ink-ai/portal/reducers/article';
import { RoundedButton } from '../../Buttons';
import { CollapseCard } from '../../cards/CollapseCard';
import { StatusDisplay } from './StatusDisplay';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  CardContent,
  Modal,
} from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '@ink-ai/portal/reducers';
import StopIcon from '@mui/icons-material/Stop';
import AutoFixHighOutlinedIcon from '@mui/icons-material/AutoFixHighOutlined';
import SectionPreview from './components/SectionPreview';
import { connectorFilterActions } from '@ink-ai/portal/reducers/connector-filter';
import { composeInstructionActions } from '@ink-ai/portal/reducers/compose-instruction';
import { isOffice } from '@ink-ai/portal/common/utils';
import { UploadFileComponent } from './UploadFile';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AttachmentIcon from '@mui/icons-material/Attachment';

export const GenerateContent = () => {
  const { t } = useTranslation();
  const articleState = useSelector((state: RootState) => state.article);
  const authState = useSelector((state: RootState) => state.auth);
  const composeInstruction = useSelector(
    (state: RootState) => state.composeInstruction,
  );
  const dispatch = useDispatch<any>();
  const { currentStep, steps, showSectionDialog } = articleState;

  const handleGenerateClick = () => {
    dispatch(startInstructionContentGeneration({ isRegenerate: false }));
  };

  const officePreviewDialog = useRef<Office.Dialog>(null);

  // reset instruction and connector
  useEffect(() => {
    dispatch(composeInstructionActions.resetInstruction(''));
    dispatch(connectorFilterActions.resetInstruction(''));
  }, []);

  // Open external preview window for office add-in
  useEffect(() => {
    if (showSectionDialog && isOffice()) {
      Office.context.ui.displayDialogAsync(
        `${location.protocol}//${location.host}/insight-preview.html?instanceId=${authState.instanceId}`,
        { height: 50, width: 50 },
        (res) => {
          console.log(res);
          if (res.error) {
            dispatch(
              article.actions.showSectionDialog({
                show: false,
              }),
            );
          }
          const dialog = res.value;
          officePreviewDialog.current = dialog;
          dialog.addEventHandler(
            Office.EventType.DialogMessageReceived,
            (dialogRes) => {
              console.log(dialogRes);
              if ((dialogRes as any).error) {
                dialog.close();
                dispatch(
                  article.actions.showSectionDialog({
                    show: false,
                  }),
                );
              }
              switch ((dialogRes as any).message) {
                case 'accept':
                  dispatch(article.actions.insertSectionToDocument());
                  dialog.close();
                  dispatch(
                    article.actions.showSectionDialog({
                      show: false,
                    }),
                  );
                  break;
                case 'regenerate':
                  dispatch(article.actions.resetPreviewState());
                  break;
                default:
                  break;
              }
            },
          );
          dialog.addEventHandler(
            Office.EventType.DialogEventReceived,
            (dialogRes) => {
              console.log(dialogRes);
              dispatch(
                article.actions.showSectionDialog({
                  show: false,
                }),
              );
            },
          );
        },
      );
    }
  }, [showSectionDialog]);

  useEffect(() => {
    if (officePreviewDialog.current) {
      officePreviewDialog.current.messageChild(
        JSON.stringify({
          article: articleState,
          composeInstruction,
        }),
      );
      console.log('Preview Changed');
    }
  }, [articleState, composeInstruction]);

  return (
    <>
      <InstructionInput />
      <ConnectorSelector />
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon sx={{ color: '#2196F3' }} />}
          aria-controls="panel1-content"
          id="panel1-header"
          sx={{
            backgroundColor: '#E4F2FD',
            color: '#2196F3',
            height: 48,
            minHeight: 48,
            '&.Mui-expanded': {
              height: 48,
              minHeight: 48,
            },
          }}
        >
          <AttachmentIcon sx={{ mr: 1 }} />
          {t('compose.files')}
        </AccordionSummary>
        <AccordionDetails>
          <UploadFileComponent acceptedFileTypes={['.docx', '.xlsx', '.pdf']} />
        </AccordionDetails>
      </Accordion>

      <div className="py-3">
        <RoundedButton
          variant="contained"
          fullWidth={true}
          size="large"
          onClick={handleGenerateClick}
          disabled={
            !composeInstruction.userInstructionText || articleState.isGenerating
          }
        >
          <AutoFixHighOutlinedIcon className="mr-1" />
          {t('compose.composeButton')}
        </RoundedButton>
      </div>
      {articleState.steps.length > 0 && (
        <CollapseCard open={true}>
          <CardContent className="card-content">
            <div>
              <StatusDisplay steps={steps} currentStep={currentStep} />
            </div>
            <div>
              {articleState.isGenerating && (
                <Button
                  color="error"
                  fullWidth={true}
                  onClick={() => dispatch(article.actions.stopGenerating())}
                  sx={{ mt: 1 }}
                >
                  <StopIcon className="mr-1" />
                  {t('compose.stopButton')}
                </Button>
              )}
            </div>
          </CardContent>
        </CollapseCard>
      )}
      <Modal
        open={showSectionDialog && !isOffice()}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          borderRadius: 2,
          '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: 'transparent',
          },
          '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: 'gray',
          },
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            minWidth: '800px',
            width: '75vw',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 0,
          }}
        >
          <SectionPreview />
        </Box>
      </Modal>
    </>
  );
};
