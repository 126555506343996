/*
Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.

Licensed under the Apache License, Version 2.0 (the "License").
You may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ToggleButton } from '@mui/material';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import AutoAwesomeOutlinedIcon from '@mui/icons-material/AutoAwesomeOutlined';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import { RootState } from '../../../../reducers';
import { useDispatch, useSelector } from 'react-redux';
import { ASSISTANT_TABS, aiAssistant } from '../../../../reducers/ai-assistant';
import { RoundToggleButtonGroup } from '../../Buttons';
import PlagiarismOutlinedIcon from '@mui/icons-material/PlagiarismOutlined';

type TabFlags = {
  [key in keyof typeof ASSISTANT_TABS]?: boolean;
};

export interface AssistantTabsProps {
  tabs: TabFlags;
}

export const AssistantTabs = ({ tabs }: AssistantTabsProps) => {
  const { t } = useTranslation();
  const aiAssistantState = useSelector((state: RootState) => state.aiAssistant);
  const dispatch = useDispatch();

  return (
    <RoundToggleButtonGroup
      fullWidth
      size="small"
      color="primary"
      value={aiAssistantState.currentTab}
      exclusive
      onChange={(_, newSelectedId) => {
        if (newSelectedId) {
          dispatch(aiAssistant.actions.setCurrentTab(newSelectedId));
        }
      }}
      // className="rounded-full"
      aria-label="Function"
    >
      {tabs.LANGUAGE && (
        <ToggleButton
          size="small"
          value={ASSISTANT_TABS.LANGUAGE}
          className="whitespace-nowrap"
        >
          <DriveFileRenameOutlineIcon
            sx={{ fontSize: '1rem' }}
            className="mr-1"
          />
          {aiAssistantState.currentTab === ASSISTANT_TABS.LANGUAGE &&
            t('assistantTabs.language')}
        </ToggleButton>
      )}
      {tabs.COMPOSE && (
        <ToggleButton
          size="small"
          value={ASSISTANT_TABS.COMPOSE}
          className="whitespace-nowrap"
        >
          <AutoAwesomeOutlinedIcon sx={{ fontSize: '1rem' }} className="mr-1" />
          {aiAssistantState.currentTab === ASSISTANT_TABS.COMPOSE &&
            t('assistantTabs.compose')}
        </ToggleButton>
      )}
      {tabs.INSPECT && (
        <ToggleButton
          size="small"
          value={ASSISTANT_TABS.INSPECT}
          className="whitespace-nowrap"
        >
          <PlagiarismOutlinedIcon sx={{ fontSize: '1rem' }} className="mr-1" />
          {aiAssistantState.currentTab === ASSISTANT_TABS.INSPECT &&
            t('assistantTabs.inspect')}
        </ToggleButton>
      )}
      {tabs.CITATION && (
        <ToggleButton
          size="small"
          value={ASSISTANT_TABS.CITATION}
          className="whitespace-nowrap"
        >
          <AutoStoriesIcon sx={{ fontSize: '1rem' }} className="mr-1" />
          {aiAssistantState.currentTab === ASSISTANT_TABS.CITATION &&
            t('assistantTabs.citation')}
        </ToggleButton>
      )}
      {tabs.CHATBOT && (
        <ToggleButton
          size="small"
          value={ASSISTANT_TABS.CHATBOT}
          className="whitespace-nowrap"
        >
          <ChatOutlinedIcon sx={{ fontSize: '1rem' }} className="mr-1" />
          {aiAssistantState.currentTab === ASSISTANT_TABS.CHATBOT &&
            t('assistantTabs.chatbot')}
        </ToggleButton>
      )}
    </RoundToggleButtonGroup>
  );
};
